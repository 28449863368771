import { FormControl, MenuItem, Select } from '@mui/material'
import { keyBy } from 'lodash'
import { useDeepCompareMemo } from 'use-deep-compare'

import { useCategoriesAnalyticsTracking } from '@/utils/frontend/analytics/useCategoriesAnalyticsTracking'
import { useNormalizedSortBy } from '@/utils/frontend/useNormalizedSortBy'
import { useT } from '@/utils/frontend/useT'

// Desktop version should be a dropdown with options
export const SortByDropdown = ({ dataCyPrefix }: { dataCyPrefix: string }) => {
  const { t } = useT({ keyPrefix: 'catalog.filters' })
  const {
    currentRefinement,
    options,
    refine,
    initialIndex,
    originalItems,
    analyticsEventNames,
  } = useNormalizedSortBy()

  const { trackFilterEvent } = useCategoriesAnalyticsTracking()

  const optionsMap = useDeepCompareMemo(() => keyBy(options, 'value'), [options])

  return (
    <FormControl variant="standard" data-cy={`${dataCyPrefix}-SortByDropdown`}>
      {/* Cannot directly add `aria-label` to HTML-native with MUI somehow, this is a workaround */}
      {}
      <label id="sorting-label" style={{ display: 'none' }}>
        {t('sort-by-label')}
      </label>
      <Select
        variant="outlined"
        defaultValue={initialIndex}
        onChange={(e) => {
          const selectedValue = e.target.value

          trackFilterEvent('productSorting', [analyticsEventNames[selectedValue]])
          refine(selectedValue)
        }}
        value={currentRefinement}
        renderValue={(value) => `${t('sort-by-label')}: ${optionsMap[value].label}`}
        inputProps={{
          'aria-label': t('sort-by-label'),
          'aria-labelledby': 'sorting-label',
        }}
      >
        {options.map((option, index) => (
          <MenuItem
            key={option.value}
            value={option.value}
            data-cy={`${dataCyPrefix}-SortByDropdown-MenuItem-${originalItems[index].label}`}
          >
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
